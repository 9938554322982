.ReactModal__Overlay--after-open {
    z-index: 1;
    backdrop-filter: blur(2px);
    background: rgba(255, 255, 255, 0.10) !important;
}

.ReactModal__Content--after-open {
    padding: 0 !important;
    width: 30%;
    max-width: 30%;
    // min-height: 30%;
    border-radius: 8px !important;
    box-shadow: 0px 0px 15px 0px rgba(250, 189, 0, 0.10);
    border: none !important;
}
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.delete-popup-wrapper {
    display: flex;
    flex-direction: column;
    color: white;

    .header {
        display: flex;
        justify-content: space-between;
        background-color: #292A27;
        padding: 15px 10px;

        .label {}
        img {
            border: 1px solid var(--primary-brand);
            scale: 0.9;
            cursor: pointer;
        }
    }

    .content {
        padding: 15px 10px 0 10px;
        background-color: black;
        min-height: 75px;
        display: flex;
        justify-content: center;
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        background-color: #292A27;
        padding: 14px;

        .cancel-btn {
            border-radius: 4px;
            border: 1px solid #FABD00;
            color: var(--brand-primary, #F9CD45);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            background-color: inherit;
            cursor: pointer;
        }

        .delete-btn {
            border-radius: 4px;
            background: var(--brand-primary, #FABD00);
            color: var(--default-font, #111827);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            margin-left: 7px;
            cursor: pointer;
        }
    }
}