@import '../../../assets/styles/mixins.scss';

.field-container {
    position: relative;
    height: 48px;
    width: 100%;

    width: -webkit-fill-available label {
        position: absolute;
        position: absolute;
        top: -8px;
        left: 10px;
        background-color: white;
        padding: 0 4px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: gray;
    }

    input {
        height: inherit;
        width: inherit;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        outline: none;
    }

    input::placeholder {
        color: var(--neutral-neutral-700, #374151);
        font-family: Poppins;
        font-size: 12x;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.primary-input {
    @include primary-background-input
}

.error {
    position: absolute;
    color: red;
    font-size: 0.6rem;
    margin-top: 7px;
}