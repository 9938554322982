$colorAccent: #fabd00;

$colorDarkBg: #111827;
$colorDarkerBg: #1b1e22;
$colorDarkBorder: #374151;

$colorBg: #f2f6fa;
$colorEEE: #eee;
$colorWhite: #fff;

$colorRed: #b91c1c;

$colorBlack: #000;
$colorText: #111827;
$colorTextDarkGrey: #2e363e;
$colorTextNeutral: #e5e7eb;
$colorTextGrey: #9ca3af;
$colorPageContentText: #6B7280;
$colorPageContentTextBold: #374151;
$colorPageContentTextAnchor: #B91C1C;

$colorCardBg: #23262b;
$colorAccordionBg: #1f2937;

