@import '../../assets/styles/mixins.scss';

.page-heading {
    color: var(--default-white);
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 20px;
}

.hr {
    width: 95%;
    border: 1px solid #25292D;
}