@import "../../assets/styles/colors.scss";
@import "../../assets/styles/mixins.scss";

:root {
  --header-height: 120px;
  --home-section-vpad: 80px;
  --font-montserrat: 'Montserrat', sans-serif;
  --home-hero-height: 100vh;
}

html {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  position: relative;
}
body.website {
  color: $colorText;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  position: relative;

  #root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .btn {
    font-family: inherit;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 2em;

    &[data-with-accent] {
      position: relative;

      &:before {
        @include headingAccent;
      }
    }
    &[data-with-accent="left"]::before {
      left: 0;
    }
    &[data-with-accent="right"]::before {
      right: 0;
    }
    &[data-with-accent="center"]::before {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .clickable {
    cursor: pointer;
  }
}