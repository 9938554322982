@import "./colors.scss";

@mixin fw($weight: 'regular') {
  @if $weight == 'regular' {
    font-weight: 400;
  }
  @else if $weight == 'semibold' {
    font-weight: 600;
  }
  @else if $weight == 'bold' {
    font-weight: 700;
  }
}

@mixin headingAccent {
  background-color: $colorAccent;
  border-radius: 2px;
  content: '';
  display: block;
  height: 4px;
  max-width: 100%;
  position: absolute;
  top: 100%;
  width: 63px;
}