@import '../../assets/styles/mixins.scss';

.sidebar-wrapper {
    min-width: 200px;
    background-color: var(--light-black);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .logo {
        background: var(--primary-brand);
        border-radius: 7px;
        height: 35px;
        width: 35px;
        display: flex;
        box-sizing: border-box;
        margin-left: 10px;
        align-items: center;
        justify-content: center;
    }

    .fletcher-text {
        scale: 0.9;
    }

    .red-cross {
        scale: 0.8;
    }
}

.sidebar-nav {
    flex-grow: 3;
    align-self: center;
    width: 100%;
    padding-top: 20px;
    position: relative;
}

.sidebar-nav-items {
    list-style: none;
    padding: 10px 0 10px 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-white-color);

    li {
        padding-top: 20px;
        padding-bottom: 20px;


        a {
            text-decoration: none;
            padding-left: 14px;
            box-sizing: border-box;
            padding: 10px 0px 10px 8px
        }

        .name {
            margin-left: 20px;
        }

        .active {
            background-color: var(--default-black-3);
            color: var(--primary-brand);
            width: 100%;
            display: flex;
            border-radius: 10px;
            border-left: 3px solid var(--primary-brand);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

        }
    }
}

// .logout-icon {
//     align-self: center;
//     flex-grow: 0.5;
//     color: var(--primary-white-color);

//     .logout-icon-image {
//         margin-right: 15px;
//     }
// }
// .logout-button {
//     cursor: pointer;
// }
// .logout-button:hover {
//     font-weight: 500;
// }

.logout-wrapper {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    text-align: center;

    .logout-btn {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        border-radius: 1rem;
        padding: 1rem;
        background: var(--neutral-neutral-900, #111827);
        margin: auto;
        width: 90%;
        color: white;
        color: var(--neutral-neutral-50, #F9FAFB);
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.5rem;
        cursor: pointer;
    }
}