@import "../../assets/styles/colors.scss";

.page-home {
  background: $colorDarkBg url(../../assets/graphics/png/bg-homepage.png) center var(--home-hero-height) / contain no-repeat;
  color: $colorWhite;
}

.home-section {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--home-section-vpad);

  .section-adj-space {
    display: block;
    height: calc(var(--home-section-vpad) + var(--header-height));
  }

  &.section__home {
    background-color: $colorAccent;
    color: $colorText;
    min-height: var(--home-hero-height);
    overflow: hidden;
  }

  &.section__about {
    padding-bottom: calc(var(--home-section-vpad) + 44px);
  }

  &.section__faq {
    .section-adj-space {
      height: calc(var(--header-height) - 10px); // to make the FAQ heading stick to the top when the menu item is clicked
    }
  }

  .btn-group {
    & > :not(.btn-check:first-child) + .btn {
      // margin-left: 20px;
    }
    
    .btn {
      background: transparent none center center no-repeat;
      border: 0;
      height: 62px;
      width: 211px;
    }
    .apple {
      background-image: url(../../assets/graphics/png/get-mobile-app-apple.png);
    }
    .google {
      background-image: url(../../assets/graphics/png/get-mobile-app-google.png);
      // margin-left: 20px;
    }
  }
}
