@import "../../assets/styles/colors.scss";
@import "../../assets/styles/mixins.scss";

.home-section {
  &.section__home {
    .callout {
      // background-color: $colorWhite;
      border-radius: 24px 24px 24px 0;
      font-family: 'DM Sans', sans-serif;
      font-size: 30px;
      line-height: 24px;
      margin: 0 auto 24px 0;
      // padding: 16px;
    }
    h1 {
      color: $colorTextDarkGrey;
      font-family: var(--font-montserrat);
      font-size: 38px;
      @include fw(bold);
      letter-spacing: -1px;
      line-height: 60px;
      margin: 0;

      @media all and (max-width: 480px) {
        // special values to ensure that the words `artificial intelligence` stay in one line
        font-size: 8.6vw;
        line-height: 13.57vw;
      }

      em {
        color: $colorRed;
        font-style: normal;
      }
    }

    p {
      font-size: 22px;
      line-height: 32px;
      margin: 24px 0 0;
    }

    .btn-group {
      margin: 40px auto 0;
    }

    .iphone {
      display: flex;
      flex-direction: column;
      // height: 556px;
      margin: 0 auto;
      position: relative;
      // width: 276px;
      max-width: 276px;
      padding-top: 90%;
      width: 36vh;

      .phone {
        // background: transparent url(../../assets/graphics/png/mobile-app-screen.png) center / contain no-repeat;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
        object-fit: contain;
      }
    }
    .aura {
      background: transparent url(../../assets/graphics/svg/mobile-app-aura.svg) center bottom / contain no-repeat;
      bottom: -15%;
      left: -28%;
      padding-top: 100%;
      position: absolute;
      right: -28%;
      top: 0;
      z-index: 0;
    }
  }
}
