@import '../../assets/styles/mixins.scss';

.main-wrapper {
    // display: flex;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;

    .layout-header-wrapper {
        // box-shadow: 0px 8px 16px -7px rgba(3, 29, 53, 0.08);
    }

    .content {
        display: flex;
        height: 100vh;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: hidden;

        // flex-direction: column;
        .sidebar-menu {
            // border: 1px solid;
        }

        .dashboard-components-holder {
            flex-grow: 1;
        }
    }
}