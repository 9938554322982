@import '../../assets/styles/mixins.scss';

table {
    border-spacing: 0;
    width: 100%;
    background-color: var(--default-black-3);
    // border-radius: 20px;
    color: var(--color-text-light);
    font-family: "Archivo", sans-serif;

    // text-transform: capitalize;
    thead {
        background-color: var(--default-black-3);
        // border-radius: 24px;
        font-size: 12px;
        color: var(--default-white);
        font-weight: 400;

        tr {
            color: var(--default-white);;
            // font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            // border-radius: 50px !important;
        }

        th {
            padding: 12px;
            text-align: left;
        }

        // th:first-child {
        //     border-radius: 24px 0 0 0;
        // }

        // th:last-child {
        //     border-radius: 0 24px 0 0;
        // }
    }

    tbody {
        td {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        tr:hover {
            background-color: #363739;
        }
    }

    td {
        padding: 12px;
        text-align: left;
        color: var(--default-white);
    }
}

.table-fixed-head {
    pointer-events: none;
}

.custom-order-table {
    height: 35vh;
    overflow-x: hidden;
    position: relative;
    border: 1px solid lightgray;
}

.table-wrapper {
    overflow: auto !important;
    height: 100%;
    background-color: var(--default-black-3);
}

.orders-table .table-wrapper {
    border: 1px solid #d3d3d3 !important;
    max-height: 35vh;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding: 0 0 20px;
    // border-top-left-radius: 40px;
    // border-top-right-radius: 40px;
}

table {
    thead {
        position: sticky;
        top: 0;
    }
}