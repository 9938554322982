@import '../../../assets/styles/mixins.scss';

.button {
    width: 100%;
    height: inherit;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Poppins';
}

.btn-dark {
    background: var(--default-black-2);
    color: var(--default-white);
}