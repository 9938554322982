@import './assets//styles/mixins.scss';
html {
  height: 100%;
}

html body:not(.website) {
  margin: 0;
  min-height: 100%;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--default-black);

  #root {
    height: 100vh;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}



// .modal-backdrop, .fade  {
  // position: fixed;
  // top: 0;
  // height: 100vh;
  // width: 100vw;
  // background: gray;
  // opacity: 0.4;
  // z-index: 5;
// }

// display: block;
// padding-right: 8px;
// position: fixed;
// top: 0;
