@import '../../../assets/styles/mixins.scss';

.field-container {
    position: relative;
    height: 48px;
    width: 100%;
    width: -webkit-fill-available

    input {
        height: inherit;
        width: inherit;
        padding: 3px;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
    }
}

.primary-input {
    @include primary-background-input
}

.password-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}

.error {
    position: static;
    color: red;
    font-size: 0.7rem;
    margin-top: 7px;
    display: flex;
    flex-wrap: wrap;
}