@import '../../../assets/styles/mixins.scss';

.users-wrapper {
    padding: 0 1rem 1rem 1rem;
    height: 94%;
    display: flex;
    flex-direction: column;

    .main-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .filter-actions {
        display: flex;
        justify-content: space-between;
        padding: 0.625rem 0;

        .left-search {
            position: relative;

            input {
                border-radius: 0.5rem;
                border: 1px solid #3E424A;
                background: #1C1F21;
                display: flex;
                padding: 0.75rem 0.875rem 0.625rem 2.05rem;
                align-items: center;
                gap: 0.625rem;
                color: var(--default-white);
            }

            .assets-icon {
                position: absolute;
                top: 9px;
                left: 10px;
            }
        }

        .right-actions {
            display: flex;

            .user-type-ddn {
                margin-right: 10px;
                width: 10rem;
                text-transform: capitalize;
            }

            .ddn-btn {
                border-radius: 0.5rem;
                border: 1px solid #3E424A;
                background: #1C1F21;
                color: white;
                display: flex;
                justify-content: space-between;
                padding: 5px;
                width: 100%;
                cursor: pointer;
                box-sizing: border-box;
                position: relative;

                .down-arrow {
                    position: absolute;
                    right: 6px;
                    top: 7px;
                }
            }


            .user-type-options {
                display: flex;
                flex-direction: column;
                background-color: white;
                position: absolute;
                z-index: 2;
                width: inherit;
                display: flex;
                flex-direction: column;
                background-color: #363739;

                div {
                    padding: 5px;
                    color: white;
                    border-bottom: 1px solid #1C1F21;
                    cursor: pointer;
                }
            }

            .add-user {
                button {
                    border-radius: 4px;
                    background: var(--primary-brand);
                    height: 35px;
                    border-radius: 5px;
                    padding: 5px;
                    cursor: pointer
                }
            }
        }
    }
}


.user-action-wrapper {
    display: flex;

    .edit-icon {
        border: 1px solid var(--primary-brand);
        padding: 5px;
        scale: 0.8;
        cursor: pointer;
    }

    .trash-icon {
        border: 1px solid var(--default-white);
        padding: 5px;
        margin-left: 10px;
        scale: 0.8;
        cursor: pointer;
    }

    .flag-user {
        color: var(--default-black-2);
        cursor: pointer;
        // font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-left: 10px;
        border-radius: 4px;
        border: 1px solid var(--default-white);
        background: var(--primary-brand);
        min-width: 85px;
    }

    .unflag-user {
        color: var(--default-white);
        cursor: pointer;
        // font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-left: 10px;
        border-radius: 4px;
        border: 1px solid var(--default-white);
        background-color: transparent;
        min-width: 85px;
    }

}

.footer-wrapper {
    background: var(--default-black-4);
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;

    .limit-setter {
        display: flex;
        color: white;
        align-items: center;

        .limit-options {
            display: flex;
            flex-direction: column;
            background-color: #363739;
            ;
            color: white;
            position: absolute;
            bottom: 35px;
            z-index: 1;
            width: inherit;

            span {
                padding: 5px;
                border-bottom: 1px solid #1C1F21;
            }
        }
    }

    .limit-ddn {
        margin-left: 10px;
        width: 60px;
        border-radius: 2px;
        border: 1px solid #3E424A;
        background: #1C1F21;
        position: relative;

        .ddn-btn {
            padding: 5px 5px;
            position: relative;

            .selected-user-type {
                text-transform: capitalize;
            }

            .down-icon {
                position: absolute;
                right: 3px;
                top: 7px;
            }
        }
    }

    .pages-view {
        display: flex;

        .page {
            margin-left: 10px;
            width: auto;
            min-width: 30px;
            border: 1px solid #3E424A;
            background: #1C1F21;
            border-radius: 4px;

            .page-btn {
                padding: 5px 5px;
                text-align: center;
                position: relative;
                color: white;
                border-radius: inherit;
                position: relative;
                text-align: center;
                cursor: pointer;

                .more-text {
                    position: absolute;
                    font-size: 10px;
                    text-align: center;
                    color: var(--primary-brand);
                }
            }

            .no-action {
                cursor: inherit;
            }

            .page-btn-active {
                background-color: var(--primary-brand);
                color: black;
            }
        }
    }

    .nextPrev-disabled {
        opacity: 0.2;
    }
}
.user-name-image {
    display: flex;
    align-items: center;
    img {
        height: 50px;
        width: 50px;
        border-radius: 25px;
    }
    p{
        margin-left: 15px;
    }
}

.text-capitalize {
    text-transform: capitalize;
}