$global-phone-only: 'phone-only';
$global-tablet-portrait-up: 'tablet-portrait-up';
$global-tablet-landscape-up: 'tablet-landscape-up';
$global-desktop-up: 'desktop-up';
$global-big-desktop-up: 'big-desktop-up';

:root {
    // color variables 
    --primary-brand: #FABD00;
    --default-black: black;
    --default-black-2: #111827;
    --default-black-3: #1F201D;
    --default-black-4: #292A27;;
    --light-black: #222425;
    --default-white:#FFFFFF;
    --font-red: #B91C1C;

}