@import '../../assets/styles/mixins.scss';

.login-wrapper {
    width: 35%;
    padding: 30px 15px;
    background-color: var(--primary-brand);
    border-radius: 16px;
    box-shadow: 0px 0px 96px 3px rgba(250, 189, 0, 0.20);
    font-family: Poppins, sans-serif;

    @include for-size($global-desktop-up) {
        width: 25%;
    }
    
    @include for-size($global-phone-only){
        width: 85%;
    }

    .fletcher-logo {
        max-height: 60px;
        max-width: 60px;
    }

    .fletcher-text {
        height: 70px;
        width: 225px;
        margin-left: 30px;
    }

    .login-heading {
        color: var(--default-font, #111827);
        font-family: Poppins, sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20px;
    }

    .login-field {
        margin-bottom: 25px;
    }

    .login-button {
        height: 48px;
    }

    .forgot-password {
        font-family: Roboto;
        color: var(--default-black-2);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: center;

        .help {
            color: var(--font-red);
            cursor: pointer;
        }
    }
}

.signup-heading {
    margin-bottom: 0 !important;
}

.signup-sub-heading {
    margin-bottom: 20px;
    color: #1F2937;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
